<script setup>
import { useLocale } from "vuetify";

const { t } = useLocale();
</script>
<template>
  <v-footer class="p-5 footer">
    <v-row class="align-baseline">
      <v-col lg="4" md="4" sm="12" xs="12" cols="12">
        <div>
          <v-img :src="logo" class="img-fluid mb-2" width="128" />

          <hr class="hr-colored col-lg-3 col-md-3 col-6" />
          <ul
            class="navbar-nav text-white"
            :class="[
              { 'text-left': $vuetify.locale.current == 'en' },
              { 'text-right': $vuetify.locale.current == 'ar' },
            ]"
          >
            <li class="nav-item nav-link">
              <font-awesome-icon icon="fas fa-location" class="text-colored" />
              <span
                :class="[
                  { 'ml-2': $vuetify.locale.current == 'en' },
                  { 'mr-2': $vuetify.locale.current == 'ar' },
                ]"
                >{{ Address }}</span
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" href="phone:852994336">
                <font-awesome-icon icon="fas fa-phone" class="text-colored" />
                <span
                  :class="[
                    { 'ml-2': $vuetify.locale.current == 'en' },
                    { 'mr-2': $vuetify.locale.current == 'ar' },
                  ]"
                  >{{ Phone }}</span
                >
              </a>
            </li>
            <li class="nav-item">
              <a href="mailto:support@exapmle.com" class="nav-link mt-0">
                <font-awesome-icon
                  icon="fas fa-paper-plane"
                  class="text-colored"
                />
                <span
                  :class="[
                    { 'ml-2': $vuetify.locale.current == 'en' },
                    { 'mr-2': $vuetify.locale.current == 'ar' },
                  ]"
                  >{{ Email }}</span
                >
              </a>
            </li>
          </ul>
        </div>
      </v-col>
      <v-col lg="4" md="4" sm="12" xs="12" cols="12">
        <div>
          <h5
            class="text-uppercase text-colored"
            :class="[
              { 'text-left': $vuetify.locale.current == 'en' },
              { 'text-right': $vuetify.locale.current == 'ar' },
            ]"
          >
            <font-awesome-icon icon="fas fa-link" />
            <span class="text-white ms-2">{{
              t("$vuetify.footer.title")
            }}</span>
          </h5>
          <hr class="hr-colored col-lg-5 col-md-5 col-10" />
          <v-row justify="start">
            <v-col>
              <h3>
                <a class="text-white" :href="FBLINK">
                  <font-awesome-icon icon="fab fa-facebook" />
                </a>
              </h3>
            </v-col>
            <v-col>
              <h3>
                <a class="text-white" :href="IGLINK" target="_blank">
                  <font-awesome-icon icon="fab fa-instagram" />
                </a>
              </h3>
            </v-col>
            <v-col>
              <h3>
                <a class="text-white" :href="TKLINK">
                  <font-awesome-icon icon="fab fa-tiktok" />
                </a>
              </h3>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <h3>
                <a class="text-white" :href="XLINK">
                  <font-awesome-icon icon="fab fa-twitter" />
                </a>
              </h3>
            </v-col>
            <v-col>
              <h3>
                <a class="text-white" :href="YTLINK">
                  <font-awesome-icon icon="fab fa-youtube" />
                </a>
              </h3>
            </v-col>
            <v-col>
              <h3>
                <a class="text-white" :href="SCLINK">
                  <font-awesome-icon icon="fab fa-snapchat" />
                </a>
              </h3>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  data() {
    return {
      logo: require("@/assets/img/logo.png"),
    };
  },
  props: {
    Address: String,
    Email: String,
    Phone: String,
    FBLINK: String,
    YTLINK: String,
    XLINK: String,
    IGLINK: String,
    TKLINK: String,
    SCLINK: String,
  },
};
</script>
