import { createStore } from "vuex";

function updateLocalStorage(cart, subtotal) {
  localStorage.setItem("cart", JSON.stringify(cart));
  localStorage.setItem("subtotal", subtotal);
}

export default createStore({
  state: {
    cart: [],
    subtotal: 0,
    APP_NAME: "",
    TOKEN: null,
    locale: "en",
  },
  getters: {
    productQty: (state) => (product) => {
      const item = state.cart.find((i) => i.id === product.id);

      if (item) return item.qty;
      else return null;
    },
  },
  mutations: {
    addToCart(state, product) {
      let item = state.cart.find((i) => i.id === product.id);

      if (item) {
        item.qty++;
        if (item.discountPrice != null) {
          state.subtotal += parseFloat(item.discountPrice);
        } else {
          state.subtotal += parseFloat(item.price);
        }
      } else {
        state.cart.push({ ...product, qty: 1 });
        if (product.discountPrice != null) {
          state.subtotal += parseFloat(product.discountPrice);
        } else {
          state.subtotal += parseFloat(product.price);
        }
      }

      updateLocalStorage(state.cart, state.subtotal);
    },

    removeFromCart(state, product) {
      let item = state.cart.find((i) => i.id == product.id);

      if (item) {
        if (item.qty > 1) {
          item.qty--;
          if (item.discountPrice != null) {
            state.subtotal -= item.discountPrice;
          } else {
            state.subtotal -= item.price;
          }
        } else {
          state.cart = state.cart.filter((i) => i.id !== product.id);
          if (item.discountPrice != null) {
            state.subtotal -= item.discountPrice;
          } else {
            state.subtotal -= item.price;
          }
        }
      }

      updateLocalStorage(state.cart, state.subtotal);
    },
    updateCartFromLocalStorage(state) {
      if(localStorage.getItem('locale') == null){
        localStorage.setItem('locale','en')
      }
      const cart = localStorage.getItem("cart");
      const subtotal = localStorage.getItem("subtotal");
      const APP_NAME = localStorage.getItem("APP_NAME");
      const TOKEN = localStorage.getItem("_token");
      state.APP_NAME = APP_NAME;
      state.TOKEN = TOKEN;

      if (cart) {
        state.cart = JSON.parse(cart);
        state.subtotal = parseFloat(subtotal);
      }
    },
    setName(state, name) {
      state.APP_NAME = name;
      localStorage.setItem("APP_NAME", name);
    },
    changeLocale(state, locale) {
      state.locale = locale;
      localStorage.setItem("locale", locale);
    },
    logOut(state) {
      state.TOKEN = null;
      localStorage.removeItem("_token");
    },
  },

  actions: {},
  modules: {},
});
