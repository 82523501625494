<script setup>
import { useLocale } from "vuetify";

const { t } = useLocale();
</script>
<template>
  <nav
    class="navbar navbar-expand-lg shadow-lg shadow-sm sticky-top bg-body-tertiary"
  >
    <div class="container">
      <a class="navbar-brand" href="/">
        <img :src="logo" width="128" />
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#myNavbar"
        aria-controls="myNavbar"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse flex-grow-1" id="myNavbar">
        <ul class="navbar-nav ms-auto flex-nowrap font-18">
          <li class="nav-item">
            <a class="nav-link m-2 menu-item link-hover" href="/">{{
              t("$vuetify.navbar.home")
            }}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link m-2 menu-item link-hover" href="/product/shop">{{
              t("$vuetify.navbar.store")
            }}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link m-2 menu-item link-hover" href="/contact">{{
              t("$vuetify.navbar.contact")
            }}</a>
          </li>
          <li class="nav-item">
            <div class="dropdown">
              <button
                class="nav-link mt-2 dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span v-if="$store.state.TOKEN == null">{{
                  t("$vuetify.navbar.account")
                }}</span>
                <span v-if="$store.state.TOKEN != null">{{ Name }}</span>
              </button>
              <ul class="dropdown-menu" v-if="$store.state.TOKEN == null">
                <li>
                  <a class="dropdown-item" href="/auth/signup">{{
                    t("$vuetify.navbar.register")
                  }}</a>
                </li>
                <li>
                  <a class="dropdown-item" href="/auth/signin">{{
                    t("$vuetify.navbar.login")
                  }}</a>
                </li>
              </ul>
              <ul class="dropdown-menu" v-if="$store.state.TOKEN != null">
                <li>
                  <a class="dropdown-item" href="/clientarea">{{
                    t("$vuetify.navbar.client")
                  }}</a>
                </li>
                <li>
                  <a class="dropdown-item" href="#" @click="logOut()">{{
                    t("$vuetify.navbar.logout")
                  }}</a>
                </li>
              </ul>
            </div>
          </li>
          <li class="nav-item">
            <a
              class="nav-link m-2 menu-item link-hover"
              href="#"
              @click="changeLocale('ar')"
              >{{ t("$vuetify.navbar.ar") }}</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link m-2 menu-item link-hover"
              href="#"
              @click="changeLocale('en')"
              >{{ t("$vuetify.navbar.en") }}</a
            >
          </li>
        </ul>

        <ul class="navbar-nav font-18">
          <li class="nav-item">
            <v-btn flat color="transparent" @click="overlay = !overlay">
              <v-badge :content="$store.state.cart.length" floating color="red">
                <font-awesome-icon icon="fas fa-bag-shopping" class="font-18" />
              </v-badge>
            </v-btn>
          </li>
        </ul>
      </div>
    </div>
  </nav>

  <v-navigation-drawer
    v-model="overlay"
    location="right"
    :width="400"
    fixed
    temporary
    app
    style="z-index: 9999"
    class="rounded-b-shaped rounded-t-shaped bg-body-tertiary"
  >
    <v-row align="baseline" justify="space-between" class="mt-4">
      <h5
        class="text-colored text-uppercase"
        :class="[
          { 'mr-5': $vuetify.locale.current == 'ar' },
          { 'ml-5': $vuetify.locale.current == 'en' },
        ]"
      >
        <font-awesome-icon icon="fas fa-cart-shopping" class="mr-1" />
        {{ t("$vuetify.cart.title") }}
      </h5>
      <v-btn flat class="mr-5 bg-body-tertiary" @click="overlay = !overlay">
        <font-awesome-icon icon="fas fa-close" />
      </v-btn>
    </v-row>
    <hr class="hr-colored mt-4" />
    <div v-if="$store.state.cart.length == 0">
      <i>{{ t("$vuetify.cart.empty") }}</i>
    </div>
    <v-card
      v-for="product in $store.state.cart"
      :key="product.id"
      flat
      class="m-4 mt-5 bg-body-tertiary"
    >
      <v-card-title>
        <v-row align="center" justify="stretch">
          <div>
            <img
              :src="product.productMainImage"
              cover
              height="70"
              class="rounded-3"
            />
          </div>

          <div class="text-left ml-5">
            <h6 class="text-colored">
              <router-link
                :to="'/product/details/' + product.slug"
                replace
                class="nav-link"
              >
                {{ product.productName }}
              </router-link>
            </h6>
            <h6 class="mb-0" v-if="product.discountPrice == null">
              {{ product.price }} LYD
            </h6>
            <h6 class="mb-0" v-if="product.discountPrice != null">
              {{ product.discountPrice }} LYD
            </h6>
            <h6 class="mb-0">
              {{ t("$vuetify.cart.qty") }}:
              <v-btn
                flat
                icon
                class="bg-body-tertiary"
                @click="$store.commit('addToCart', product)"
              >
                <font-awesome-icon icon="fas fa-add" class="text-colored" />
              </v-btn>
              <strong class="ml-2 mr-2">{{ product.qty }}</strong>
              <v-btn
                flat
                icon
                class="bg-body-tertiary"
                @click="$store.commit('removeFromCart', product)"
              >
                <font-awesome-icon
                  icon="fas fa-subtract"
                  class="text-colored"
                />
              </v-btn>
            </h6>
          </div>
        </v-row>
      </v-card-title>
    </v-card>
    <hr class="hr-colored" />
    <v-row justify="space-evenly" class="mt-2">
      <v-col lg="2" md="2" sm="3" xs="3" cols="3"
        ><h6>{{ t("$vuetify.cart.subtotal") }}</h6></v-col
      >
      <v-col lg="8" md="8" sm="8" xs="8" cols="8"
        ><h6 class="text-right">{{ $store.state.subtotal }} LYD</h6></v-col
      >
    </v-row>
    <hr class="hr-colored" />
    <v-row class="mt-2">
      <v-col>
        <router-link to="/checkout">
          <v-btn class="bg-colored text-white">
            <font-awesome-icon icon="fas fa-credit-card" class="mr-1" />
            {{ t("$vuetify.cart.check") }}
          </v-btn>
        </router-link>
      </v-col>
    </v-row>
  </v-navigation-drawer>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      title: "",
      Name: null,
      logo: require("@/assets/img/logo.png"),
      links: [
        { title: "Home", path: "/" },
        { title: "Store", path: "/product/shop" },
        { title: "Contact", path: "/contact" },
      ],
      overlay: false,
    };
  },
  props: {
    appTitle: String,
  },
  methods: {
    getUserName() {
      axios
        .get(process.env.VUE_APP_BASE_API + "account/view/user/info", {
          headers: {
            Authorization: "Bearer " + this.$store.state.TOKEN,
          },
        })
        .then((res) => {
          if (res.status == 200) {
            this.Name = res.data[0].first_name + " " + res.data[0].last_name;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    logOut() {
      this.$store.commit("logOut");
      setInterval(window.location.reload(), 1000);
    },
    changeLocale(locale) {
      this.$store.commit("changeLocale", locale);
      window.location.reload();
    },
  },
  mounted() {
    if (this.$store.state.TOKEN != null) {
      this.getUserName();
    }
  },
};
</script>
