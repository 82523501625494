<script setup>
import { useLocale } from "vuetify";

const { t } = useLocale();
</script>
<template>
  <v-container fluid class="pt-5 pb-5" :class="[{ 'bg-light': tbackground }]">
    <h2 class="text-uppercase text-colored" v-if="title != null">
      {{ title }}
    </h2>
    <hr class="col-2 mx-auto mb-5 hr-colored" v-if="title != null" />
    <v-row class="justify-center">
      <v-col
        v-for="product in products"
        :key="product.id"
        lg="4"
        md="6"
        sm="12"
        xs="12"
        cols="12"
      >
        <v-card flat color="transparent">
          <v-card-title class="mb-2">
            <v-img
              :src="product.productMainImage"
              class="mb-2 align-end product-img"
              height="400"
            ></v-img>
          </v-card-title>

          <v-card-text>
            <div>
              <h5 class="float-start">
                <router-link
                  :to="'/product/details/' + product.slug"
                  class="nav-link"
                >
                  {{ product.productName }}
                </router-link>
              </h5>
              <h5
                class="ml-auto text-colored"
                :class="[
                  { 'text-left': $vuetify.locale.current == 'ar' },
                  { 'text-right': $vuetify.locale.current == 'en' },
                ]"
              >
                <span
                  v-if="product.discountPrice == null"
                  :class="[{ 'line-through': product.discountPrice != null }]"
                  >{{ product.price }} LYD</span
                >
                <span class="d-block" v-if="product.discountPrice != null"
                  >{{ product.discountPrice }} LYD</span
                >
                <sup
                  v-if="product.discountPrice != null"
                  :class="[{ 'line-through': product.discountPrice != null }]"
                  >{{ product.price }} LYD</sup
                >
              </h5>
            </div>
            <div>
              <i
                :class="[
                  { 'float-start': $vuetify.locale.current == 'en' },
                  { 'float-end': $vuetify.locale.current == 'ar' },
                ]"
                >{{ product.category }}</i
              >
              <v-btn
                flat
                class="ml-auto text-white bg-colored"
                :class="[
                  { 'mt-4': product.discountPrice == null },
                  { 'float-start': $vuetify.locale.current == 'ar' },
                  { 'float-end': $vuetify.locale.current == 'en' },
                ]"
                @click="addToCart(product)"
              >
                <font-awesome-icon icon="fas fa-cart-shopping" />
                {{ t("$vuetify.cart.add") }}
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      item: [],
    };
  },
  methods: {
    addToCart(card) {
      this.$store.commit("addToCart", card);
    },
    removeFromCart(card) {
      this.$store.commit("removeFromCart", card);
    },
  },
  props: {
    title: String,
    products: Array,
    background: Boolean,
  },
};
</script>
