<script setup>
import { useLocale } from "vuetify";

const { t } = useLocale();
</script>
<template>
  <v-app>
    <router-view :key="$route.fullPath" />
  </v-app>
</template>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>

<script>
export default {
  mounted() {
    this.$store.commit("updateCartFromLocalStorage");
    this.$vuetify.locale.current =
      localStorage.getItem("locale") == null
        ? "en"
        : localStorage.getItem("locale");
  },
};
</script>
