<template>
  <h2 class="mt-5 text-colored text-uppercase">{{ title }}</h2>
  <hr class="hr-colored col-3 mx-auto mb-5" />
  <v-row>
    <v-col
      v-for="image in Images"
      :key="image"
      lg="6"
      md="6"
      sm="12"
      xs="12"
      cols="12"
    >
      <v-card flat>
        <v-img
          :src="image"
          cover
          class="product-img align-end"
          height="300"
        ></v-img>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      items: [
        { id: 1, pic: require("@/assets/img/product/6.jpg") },
        { id: 1, pic: require("@/assets/img/product/7.jpg") },
      ],
    };
  },
  props: {
    title: String,
    Images: Array,
  },
};
</script>
