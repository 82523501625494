export default {
  navbar: {
    home: "الرئيسة",
    store: "المتجر",
    contact: "اتصل بنا",
    client: "منطقة العميل",
    logout: "تسجيل الخروج",
    account: "الحساب",
    register: "تسجيل حساب",
    login: "تسجيل الدخول",
    ar: "العربية",
    en: "الأنجليزية",
  },
  cart: {
    title: "عربة التسوق",
    empty: "عربة التسوق خالية",
    subtotal: "المجموع:",
    view: "عرض العربة",
    check: "الدفع",
    add: "اضافة الى العربة",
    qty: "العدد",
  },
  best: {
    title: "الأفضل لك",
    add: "اضافة الى العربة",
  },
  top: {
    title: "الأكثر مشاهدة",
    add: "اضافة الى العربة",
  },
  sell: {
    title: "الأكثر مبيعا",
    add: "اضافة الى العربة",
  },
  product: {
    shop: "المتجر",
    reset: "اعادة تعين ",
    key: "ادخل الكلمة المفتاحية",
    price: "مجال السعر",
    choose: "اختر المجال",
    cat: "المجموعات",
    details: "معلومات المنتج",
    review: "المراجعات",
    noreview: "لايوجد مراجعات لهذا المنتج",
    variants: "المواصفات",
    desc: "الوصف",
    similar: "منتجات مشابه",
    name: "الأسم (اختياري)",
    email: "البريد الالكتروني",
    comment: "المراجعة",
    submit: "اضافة مراجعة",
  },
  about: {
    title: "اتصل بنا",
    get: "معلومات التواصل",
    address: "عنوان المتجر",
    mail: "البريد الالكتروني",
    phone: "رقم الهاتف",
  },
  footer: {
    title: "تابعنا على منصات التواصل",
  },
  clientarea:{
    title:'منطقة العميل',
    no:'رقم',
    number:'رقم الفاتورة',
    date:'تاريخ الفاتورة',
    status:'حالة الفاتورة',
    btn:'عرض التفاصيل',
    details:'تفاصيل الفاتورة',
    name:'اسم المنتج',
    qty:'الكمية',
    price:'السعر',
    sub:'المجموع',
    pay:'طريقة الدفع',
    Pending:'قيد الانتظار',
    Refunded:'مرتجع',
    Delivered:'تم التوصيل',
    Canceled:'ملغاه',
    yet:'لايوجد طلبات !'
  },
  auth:{
    create:'انشاء حساب جديد',
    info:'الرجاء ادخال معلوماتك',
    fname:'الاسم الأول',
    lname:'الكنية',
    email:'البريد الالكتروني',
    password:'كلمة المرور',
    createBtn:'إنشاء حساب',
    have:'لديك حساب؟',
    welcome:'مرحبا بعودتك !',
    btn:'تسجيل الدخول',
    forget:'نسيت كلمة المرور',
        reset:'اعادة تعين كلمةالمرور',
    send:'ارسال الرابط',
    change:'تغير كلمة المرور',
  },
  checkout:{
    title:'الدفع',
    phone:'رقم الهاتف',
    email:'البريد الالكتروني',
    sub:'المبلغ',
    btn:'ادفع'
  }
};
