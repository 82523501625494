<script setup>
import { useLocale } from "vuetify";

const { t } = useLocale();
</script>
<template>
  <NavBar :appTitle="appName" v-if="!isLoading" />

  <v-main v-if="!isLoading">
    <HeroSection :info="StoreInfo[0]" />
    <RecomendedProduct
      :title="t('$vuetify.best.title')"
      :products="data.beastForYouProduct"
    />
    <v-container>
      <SectionIntro
        v-if="
          StoreInfo[0].storeMediaImage1 != null &&
          StoreInfo[0].storeMediaImage2 != null
        "
        :title="StoreInfo[0].storeMediaTitle1"
        :Images="[
          StoreInfo[0].storeMediaImage1,
          StoreInfo[0].storeMediaImage2,
        ]"
      />
      <TopProduct
        :title="t('$vuetify.top.title')"
        :products="data.topViewProducts"
      />
      <SpecialOffer
        v-if="StoreInfo[0].storeMediaImage3 != null"
        :header="StoreInfo[0].storeMediaTitle2"
        :title="StoreInfo[0].storeMediaDescription"
        :img="StoreInfo[0].storeMediaImage3"
      />
    </v-container>
    <section class="bg-grey-lighten-4 pb-5">
      <v-container>
        <TopProduct
          :title="t('$vuetify.sell.title')"
          :products="data.topSaleProducts"
        />
      </v-container>
    </section>
    <PageFooter
      :Address="StoreInfo[0].storeLocation"
      :Email="StoreInfo[0].storeEmail"
      :Phone="StoreInfo[0].storePhone"
      :FBLINK="StoreInfo[0].storeFaceBookLink"
      :IGLINK="StoreInfo[0].storeInstagramLink"
      :TKLINK="StoreInfo[0].storeTikTokLink"
      :XLINK="StoreInfo[0].storeXLink"
      :YTLINK="StoreInfo[0].storYoutubeLink"
      :SCLINK="StoreInfo[0].storeSnapChatLink"
    />
  </v-main>
</template>

<script>
import NavBar from "@/components/Homepage/NavBar.vue";
import HeroSection from "@/components/Homepage/HeroSection.vue";
import RecomendedProduct from "@/components/Homepage/RecomendedProduct.vue";
import SectionIntro from "@/components/Homepage/SectionIntro.vue";
import TopProduct from "@/components/Homepage/TopProduct.vue";
import SpecialOffer from "@/components/Homepage/SpecialOffer.vue";
import PageFooter from "@/components/PageFooter.vue";

import axios from "axios";
export default {
  components: {
    NavBar,
    HeroSection,
    RecomendedProduct,
    SectionIntro,
    TopProduct,
    SpecialOffer,
    PageFooter,
  },

  data() {
    return {
      isLoading: false,
      StoreInfo: [],
      data: [],
    };
  },
  methods: {
    fetchStoreInfo() {
      this.isLoading = true;
      axios
        .get(
          process.env.VUE_APP_BASE_API +
            "store/homepage/" +
            process.env.VUE_APP_STORE_DB +
            "?lang=" +
            this.$vuetify.locale.current
        )
        .then((res) => {
          if (res.status === 200) {
            this.StoreInfo = res.data.data.storeInfo;
            this.data = res.data.data;
            this.$store.commit("setName", res.data.data.storeInfo[0].storeName);
            this.isLoading = false;
          }
        });
    },
  },
  mounted() {
    this.fetchStoreInfo();
  },
};
</script>
