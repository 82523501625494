export default {
  navbar: {
    home: "Home",
    store: "Store",
    contact: "Contact",
    client: "Client Area",
    logout: "Logout",
    account: "Account",
    register: "Register",
    login: "Login",
    ar: "العربية",
    en: "English",
  },
  cart: {
    title: "Your cart",
    empty: "Your cart is empty",
    subtotal: "SubTotal:",
    view: "View Cart",
    check: "Checkout",
    add: "Add to cart",
    qty: "QTY",
  },
  best: {
    title: "Best for you",
    add: "Add to cart",
  },
  top: {
    title: "Top Views",
    add: "Add to cart",
  },
  sell: {
    title: "Top Sells",
    add: "Add to cart",
  },
  product: {
    shop: "Shop",
    reset: "Reset Filter",
    key: "Enter your keyword",
    price: "Price Range",
    choose: "Choose range",
    cat: "Categories",
    details: "Product Details",
    review: "Reviews",
    noreview: "There is no review yet",
    variants: "Variants",
    desc: "Description",
    similar: "Similar Product",
    name: "Full Name (optional)",
    email: "Email Address",
    comment: "Comment",
    submit: "Add review",
  },
  about: {
    title: "Contact Us",
    get: "Get in Touch",
    address: "Address",
    mail: "Email Address",
    phone: "Phone Number",
  },
  footer: {
    title: "Follow Us",
  },
  clientarea:{
    title:'Client Area',
    no:'No.',
    number:'Invoice Number',
    date:'Invoice Date',
    status:'Invoice Status',
    btn:'View Details',
    details:'Order Details for',
    name:'Product Name',
    qty:'Quantity',
    price:'Price',
    sub:'Sub-Total',
    pay:'Payment Method',
    Pending:'Pending',
    Refunded:'Refunded',
    Delivered:'Delivered',
    Canceled:'Canceled',
    yet:'There is no orders yet!'
  },
  auth:{
    create:'Create new account',
    info:'Please enter your information',
    fname:'First Name',
    lname:'Last Name',
    email:'Email Address',
    password:'Password',
    createBtn:'create account',
    have:'Having an account?',
    welcome:'Welcome Back !',
    btn:'Sign In',
    forget:'Forget Password?',
    reset:'Reset password',
    send:'send link',
    change:'Change Password',
  },
  checkout:{
    title:'Checkout',
    phone:'Phone Number',
    email:'Email Address',
    sub:'Subtotal',
    btn:'Checkout'
  }
};
