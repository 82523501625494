<template>
  <h2 class="mt-5 text-colored text-uppercase">{{ header }}</h2>
  <hr class="hr-colored col-3 mx-auto" />
  <p class="font-18">{{ title }}</p>
  <v-img :src="img" width="1800" height="500" cover></v-img>
</template>

<script>
export default {
  props: {
    header: String,
    title: String,
    img: String,
  },
};
</script>
