import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

// Vuetify
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";

//locale
import en from "./locale/en";
import ar from "./locale/ar";

const vuetify = createVuetify({
  components,
  directives,
  locale: {
    locale: "en",
    fallback: "en",
    messages: { en, ar },
  },
});

//Bootstrap
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

//Fontawesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fab } from "@fortawesome/free-brands-svg-icons";
library.add(fas, fab);

//css custom
import "./assets/main.css";

//Store State
import store from "./store";

//Google Maps
import VueGoogleMaps from "@fawmi/vue-google-maps";

//Image Viewer
import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer";

createApp(App)
  .use(router)
  .use(vuetify)
  .use(store)
  .use(VueViewer)
  .use(VueGoogleMaps, {
    load: {
      key: "AIzaSyCDfsSktFiU5jHwgde6UTYYHspsCCmzkqo",
    },
  })
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount("#app");
