<template>
  <section class="hero-bg" id="hero" :style="heroStyle">
    <div class="d-flex justify-center align-center vh100">
      <div>
        <h1 class="text-uppercase">{{ this.info.storeName }}</h1>
        <h4 class="col-lg-4 col-md-4 col-12 mx-auto">
          {{ info.storeCoverTitle }}
        </h4>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    info: Array,
  },
  computed: {
    heroStyle() {
      return {
        background: `linear-gradient(rgba(0,0,0,.75),rgba(0,0,0,.75)) ,url( ${this.info.storeCoverImage})`,
      };
    },
  },
};
</script>
